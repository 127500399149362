import {useState} from "react";
import {Button, InputGroup, Form, Badge, ButtonGroup} from "react-bootstrap";
import '../marketplacePage.scss';

const About = ({
	tags,
	market,
	rejectHandler,
	approveHandler,
	unpublishHandler,
 	deleteMarketTagHandler,
	addMarketTagHandler,
	switchTypeHandler,
}) => {
	const [selectedTag, setSelectedTag] = useState('all');
	const rootTags = tags.filter(t => t.rootTag);
	const marketRootTags = market?.marketplaceTags.filter(t => t.tag.rootTag);

	return (
		<>
			<div className="marginTop40">
				<div className="block marginBottom10">
					<h5 className="marginBottom10"><b>Форма отображения</b></h5>
					<div className="flex">
						<p className="marginBottom20"><Badge bg="danger">(ОБЯЗАТЕЛЬНО)</Badge>
							{` Выберите как будет отображаться данный бизнес в мобильном приложении.`}
						</p>
					</div>
					<div className="flex">
						<ButtonGroup style={{width: '100%'}}>
							{rootTags.map(t => (
								<Button
									key={t.name}
									onClick={() => {
										if (!marketRootTags.find(mrt => mrt.tag.id === t.id)) {
											switchTypeHandler(
												marketRootTags.find(mrt => mrt.tag.id !== t.id),
												rootTags.find(mt => mt.id === t.id)
											)
										}
									}}
									variant={marketRootTags.find(mrt => mrt.tag.id === t.id) ? "dark" : "outline-secondary"}
								>{t.name}</Button>
							))}
						</ButtonGroup>
					</div>
				</div>
				<div className="block marginBottom10">
					<h5 className="marginBottom20"><b>Теги</b></h5>
					<div className='grid2x'>
						<div className="flex flex-wrap">
							{market?.marketplaceTags?.length === 0 ? (
								<p>У бизнеса нет тегов</p>
							) : (
								market?.marketplaceTags.map(t => (!t.tag.rootTag && (
									<div className="ActiveTag">
										<b>{t.tag.name}</b>
										<Button variant="outline-danger" onClick={() => deleteMarketTagHandler(t)}>Удалить</Button>
									</div>
								)))
							)}
						</div>
						<div className='flex'>
							<InputGroup style={{width: '400px'}}>
								<InputGroup.Text>Теги</InputGroup.Text>
								<Form.Select
									aria-label="Выберите тег"
									aria-placeholder="Выберите тег"
									onChange={e => setSelectedTag(e.target.value)}
									value={selectedTag}
								>
									<option value={'all'} disabled>Выберите тег</option>
									{tags.filter(t => !t.rootTag).map(tag => (
										<option value={tag.id} key={tag.id}>{tag.name}</option>
									))}
								</Form.Select>
							</InputGroup>
							<Button
								variant='dark'
								disabled={selectedTag === 'all'}
								onClick={() => {
									addMarketTagHandler(selectedTag);
									setSelectedTag('all');
								}}
							>Добавить тег</Button>
						</div>
					</div>
				</div>
				<div className="grid2x">
					<div className="block">
						<h5 className="marginBottom20"><b>Последние утвержденные данные</b></h5>
						<div className="grid2x marginBottom20">
							<p>Название</p>
							<p><b>{market.name || 'Название отсутствует'}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Описание</p>
							<p><b>{market.description || 'Описание отсутствует'}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Логотип</p>
							{market.logoUrl
								? (<img style={{width: '300px'}} src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.logoUrl}`} alt=""/>)
								: (<p><b>Изображение отсутствует</b></p>)}
						</div>
						<div className="grid2x marginBottom20">
							<p>Фон каталоги</p>
							{market.catalogBackgroundUrl
								? (<img style={{width: '300px'}} src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.catalogBackgroundUrl}`} alt=""/>)
								: (<p><b>Изображение отсутствует</b></p>)}
						</div>
						<div className="grid2x">
							<p>Главное изображение</p>
							{market?.imageUrl?.full
								? (<img style={{width: '300px'}} src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.imageUrl?.preview}`} alt=""/>)
								: (<p><b>Изображение отсутствует</b></p>)}
						</div>
					</div>
					<div className="block">
						<h5 className="marginBottom20"><b>Недавно внесенные данные</b></h5>
						<div className="grid2x marginBottom20">
							<p>Название</p>
							<p><b>{market?.unverifiedChanges?.name || "Осталось без изменений"}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Описание</p>
							<p><b>{market?.unverifiedChanges?.description || "Осталось без изменений"}</b></p>
						</div>
						<div className="grid2x marginBottom20">
							<p>Логотип</p>
							{market.unverifiedLogoUrl
								? (<img style={{width: '400px'}} src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.unverifiedLogoUrl}`} alt=""/>)
								: (<p><b>Остался без изменений</b></p>)}
						</div>
						<div className="grid2x marginBottom20">
							<p>Фон каталоги</p>
							{market.unverifiedCatalogBackgroundUrl
								? (<img style={{width: '300px'}} src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.unverifiedCatalogBackgroundUrl}`} alt=""/>)
								: (<p><b>Остался без изменений</b></p>)}
						</div>
						<div className="grid2x">
							<p>Главное изображение</p>
							{market?.unverifiedImageUrl
								? (<img style={{width: '300px'}} src={`${window.__CONFIG__.REACT_APP_BASE_URL}${market.unverifiedImageUrl}`} alt=""/>)
								: (<p><b>Осталось без изменений</b></p>)}
						</div>
					</div>
				</div>
			</div>
			{market.status === 'verification'
				? (
					<div className="grid2x marginTop20">
						<Button
							variant="danger"
							onClick={() => rejectHandler()}
							className="marginBottom20"
						>Отклонить изменения</Button>
						<Button
							variant="success"
							onClick={() => approveHandler()}
							className="marginBottom20"
						>Утвердить изменения</Button>
					</div>
				)
				: (
					<div className="marginTop20 flex-center">
						<Button
							variant="danger"
							onClick={() => unpublishHandler()}
							className="marginBottom20"
						>Снять бизнес с публикации</Button>
					</div>
				)
			}
		</>
	)
};

export default About;