import {attr, hasMany, belongsTo} from "spraypaint";
import ApplicationRecord from "./baseModel";

const UserModel = ApplicationRecord.extend({
	static: { jsonapiType: "users" },
	attrs: {
		id: attr(),
		phone: attr(),
		email: attr(),
		role: attr(),
		name: attr(),
		surname: attr(),
		patronym: attr(),
		password: attr(),
		gender: attr(),
		birthday: attr(),
		avatar: attr(),
		avatarUrl: attr(),
		createdAt: attr(),
		updatedAt: attr(),
		city: belongsTo(),
		defaultAddress: belongsTo(),
		addresses: hasMany(),
		marketplaces: hasMany(),
	}
})

export default UserModel