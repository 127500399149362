import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner, Form, Badge, Modal} from "react-bootstrap";
import {getOrder} from "../../../api/orderAPI";
import AdminPageWrapper from "../../../components/adminPageWrapper/adminPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import {
	OREDER_STATUSES,
	AWAITING_PROCCESING,
	PROCESSING,
	AWAITING_PICKUP,
	PICKEDUP_COURIER,
	DELIVERED,
	PAYMENT_TYPES
} from "../../../helpers/constants/statuses";

const OrderPage = () => {
	const { marketplaceId, orderId } = useParams();
	const navigate = useNavigate();
	const [order, setOrder] = useState([]);
	const [reason, setReason] = useState('');
	const [isOpenCancel, setOpenCancel] = useState(false);

	console.log(order);

	const getCurrentOrder = async () => {
		await getOrder(orderId)
		.then(res => {
			setOrder(res);
		})
	}

	useEffect(() => {
		getCurrentOrder();
	}, []);

	const startProcessing = async () => {
		order.startProcessing = true;
		await order.save();
		getCurrentOrder();
	}

	const completeProcessing = async () => {
		order.completeProcessing = true;
		await order.save();
		getCurrentOrder();
	}

	const giveToReceiver = async () => {
		order.giveToReceiver = true;
		await order.save();
		getCurrentOrder();
	}

	const closeHandler = () => {
		setOpenCancel(false);
		setReason('');
	}

	const cancelOrderHandler = async () => {
		order.cancelByMerchant = true;
		order.statusComment = reason;

		await order.save()
		.then(() => {
			getCurrentOrder();
		})
	}

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				{order
					? (
						<>
							<Modal show={isOpenCancel} onHide={() => closeHandler()}>
								<Modal.Header closeButton>
									<Modal.Title>Отмена заказа</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
										<Form.Label>Причина отмены</Form.Label>
										<Form.Control as="textarea" value={reason} onChange={e => setReason(e.target.value)} rows={3} />
									</Form.Group>
								</Modal.Body>
								<Modal.Footer>
									<Button variant="secondary" onClick={() => closeHandler()}>
										Закрыть
									</Button>
									<Button variant="danger" disabled={!reason} onClick={() => cancelOrderHandler()}>
										Отменить заказ
									</Button>
								</Modal.Footer>
							</Modal>

							<Button
								variant="dark"
								onClick={() => {navigate(`/merchant/${marketplaceId}/orders`)}}
								className="marginBottom20"
							>Назад</Button>
							<div className="flex-space-between marginBottom20">
								<h1>Заказ: {order.publicUid}</h1>
								<div className="flex">
									{(order.status === AWAITING_PROCCESING || order.status === PROCESSING || order.status === AWAITING_PICKUP) && (
										<Button
											variant="dark"
											className="marginLeft20"
											onClick={() => setOpenCancel(true)}
										>Отменить заказ</Button>
									)}
								</div>

							</div>

							<div className="flex-space-between marginBottom40">
								<div className="flex flex-column">
									<div className="marginBottom10">
										Текущий статус заказа:
										<Badge bg={(order.status === DELIVERED || order.status === PICKEDUP_COURIER) ? "success" : "warning"}>
											{OREDER_STATUSES[order.status]?.translate || ''}
										</Badge>
									</div>
									<div className="marginBottom10">{`Общая сумма заказа: ${order.totalPrice / 100} Р`} </div>
									<div className="marginBottom10">{`Cумма доставки: ${order.deliveryPrice / 100} Р`} </div>
									<div className="marginBottom10">{`Тип доставки: ${order.deliveryType === 'courier' ? 'Курьером' : 'Самовывоз'}`} </div>
									<div className="marginBottom10">{`Способ оплаты: ${PAYMENT_TYPES[order.paymentType]}`} </div>
								</div>
							</div>
							{(order.orderItems && order.orderItems.length > 0) && (
								<>
									<h3>Состав заказа:</h3>
									<div className="marginBottom40">
										{order.orderItems.map((item, idx) => (
											<div>{`${idx + 1}) ${item.product.name || 'Имя не найдено'} - Количество ${item.quantity}шт. Цена: ${item.product.discountPrice / 100 || item.product.price / 100} P`}</div>
										))}
									</div>
								</>
							)}
							{order.comment && (
								<>
									<h3>Комментарий к заказу:</h3>
									<div className="marginBottom40">
										{order.comment}
									</div>
								</>
							)}
							{order.statusComment && (
								<>
									<h3>Комментарий к отмене заказа:</h3>
									<div className="marginBottom40">
										{order.statusComment}
									</div>
								</>
							)}
						</>
					)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default OrderPage