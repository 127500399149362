import {useEffect, useState} from "react";
import {ADMIN_ROLE} from "../../../helpers/constants/roles";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/adminPageWrapper/adminPageWrapper";
import './tagsPage.scss';
import {getAllTags} from "../../../api/tagsAPI";
import {addTagToLayout} from "../../../api/serviceConfigAPI";
import {Alert, Button, Card, Form, InputGroup, Modal, Spinner} from "react-bootstrap";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import TagModel from "../../../models/tagModel";

const TagsPage = () => {
	const [isOpen, setOpen] = useState(false);
	const [currentTag, setCurrentTag] = useState({tag: null, mode: 'create'});
	const [currentTagPreview, setPreview] = useState('');
	const [tags, setTags] = useState([]);
	const [type, setType] = useState('all');
	const [itemOnDelete, setDeleteItem] = useState(null);
	const [tagName, setTagName] = useState('');

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res.filter(i => !i.rootTag)));
	};

	const deleteHandler = async (t) => {
		await t.destroy()
		.then(() => getTags());
	}

	useEffect(() => {
		getTags();
	},[]);

	const closeHandler = () => {
		setOpen(false);
		setPreview('');
		setTagName('');
	};

	const onClickHandler = (tag) => {
		setCurrentTag({tag, mode: 'edit'});
		setTagName(tag.name);
		setPreview(`${window.__CONFIG__.REACT_APP_BASE_URL}${tag.imageUrl}`);
		setOpen(true);
	};

	const onClickCreateHandler = () => {
		setCurrentTag({tag: null, mode: 'create'});
		setPreview(``);
		setOpen(true);
	};

	const attachPreviewHandler = (file) => {
		attachHandler(file, (f, c) => setPreview({ file: f, checkSum: c }));
	};

	const updateHandler = async () => {
		if (currentTagPreview?.file) {
			currentTag.tag.image = currentTagPreview && (await uploadHandler(currentTagPreview));
		}

		currentTag.tag.name = tagName;

		await currentTag.tag.save()
		.then(() => {
			setCurrentTag(null);
			setPreview('');
			setTagName('');
			setOpen(false);
		})
	}

	const createHandler = async () => {
		const newtag = new TagModel();
		if (currentTagPreview?.file) {
			newtag.image = currentTagPreview && (await uploadHandler(currentTagPreview));
		}
		newtag.name = tagName;

		await newtag.save()
		.then(() => {
			if (currentTag.mode === 'create') {
				addTagToLayout(
					newtag.id,
					type,
					() => {
						setCurrentTag(null);
						setPreview('');
						setTagName('');
						setOpen(false);
						setType('all');
					}
				)
			} else {
				setCurrentTag(null);
				setPreview('');
				setTagName('');
				setOpen(false);
				setType('all');
			}
		})
	}

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<Modal show={isOpen} onHide={() => closeHandler()}>
					<Modal.Header closeButton>
						<Modal.Title>{currentTag?.mode === 'edit' ? 'Редактирование' : 'Создание'}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<div className="flex-column marginBottom20">
							<InputGroup className="marginBottom20">
								<InputGroup.Text>Тег</InputGroup.Text>
								<Form.Control
									aria-label="Тег"
									placeholder="Введите имя тега"
									onChange={e => setTagName(e.target.value)}
									value={tagName}
								/>
							</InputGroup>
							{currentTag.mode === 'create' && (
								<InputGroup>
									<InputGroup.Text>Разместить в</InputGroup.Text>
									<Form.Select
										onChange={e => setType(e.target.value)}
										value={type}
									>
										<option value="all" key="all" disabled>Выберите значение</option>
										<option value="store" key="all" >Магазинах</option>
										<option value="restaurant" key="all" >Ресторанах</option>
									</Form.Select>
								</InputGroup>
							)}
						</div>
						<DropZoneUploader
							preview={currentTagPreview}
							acceptPreviewHandler={(f) => {
								if (f) attachPreviewHandler(f[0]);
							}}
							className="marginBottom20"
							text="Прикрепите изображение тега в формате jpeg или png"
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={() => closeHandler()}>
							Отмена
						</Button>
						<Button variant="success" onClick={() => {
							if (currentTag.mode === 'edit') {
								updateHandler();
							} else {
								createHandler();
							}
						}}>
							Сохранить
						</Button>
					</Modal.Footer>
				</Modal>
				<h3 className="marginBottom20">Cправочник тегов</h3>
				{tags.length > 0
					? (
						<div className='grid5x'>
							<Button
								variant="dark"
								onClick={() => onClickCreateHandler()}
							>
								Добавить тег
							</Button>
							{tags.map(t => (
								<Card key={t.name} style={{ width: '100%'}}>
									<Card.Img
										variant="top"
										height={200}
										src={`${window.__CONFIG__.REACT_APP_BASE_URL}${t.imageUrl}`}
									/>
									{(itemOnDelete && itemOnDelete.id === t.id) ? (
										<Alert
											variant="warning"
											style={{ width: '100%', margin: '0', display: 'flex', justifyContent: 'space-between'}}
										>
											<div className="flex-column-center">
												<p>{`Вы действительно хотите удалить тег?`}</p>
												<div className="flex">
													<Button
														variant="success"
														className="marginRight20"
														onClick={() => deleteHandler(t)}
													>Удалить</Button>
													<Button
														variant="danger"
														onClick={() => setDeleteItem(null)}
													>Отмена</Button>
												</div>
											</div>
										</Alert>
									) : (
										<Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
											<Card.Title><b>{t.name}</b></Card.Title>
											<div className="flex-column-center">
												<Button
													className="marginBottom10"
													variant="dark"
													onClick={() => onClickHandler(t)}
													style={{width: '100%', height: '38px'}}
												>
													Редактировать
												</Button>
												<Button
													variant="dark"
													onClick={() => setDeleteItem(t)}
													style={{width: '100%', height: '38px'}}
												>
													Удалить
												</Button>
											</div>
										</Card.Body>
									)}
								</Card>
							))}
						</div>
					)
					: (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default TagsPage;