import ServiceConfigurationsModel from "../models/serviceConfigModel";

const getCurrentSCModel = async () => {
	const res = await ServiceConfigurationsModel.find('current');
	return res.data;
};

const addTagToLayout = async (tagId, layoutType, handler) => { // restaurant || store
	const currentModel = await getCurrentSCModel();

	if (layoutType === 'restaurant') {
		currentModel.addTagToRestaurantsLayout = tagId;
	} else {
		currentModel.addTagToStoresLayout = tagId;
	}

	await currentModel.save()
	.then (r => {
		handler();
	})
};

export {
	addTagToLayout
}