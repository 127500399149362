import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ADMIN_ROLE, MERCHANT_ROLE, COURIER_ROLE, USER_ROLE, AllRolesArray} from "../../../helpers/constants/roles";
import {Button, Form, InputGroup, ButtonGroup} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { getUser } from '../../../api/userAPI';
import { ru } from 'date-fns/locale/ru'
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import AdminPageWrapper from "../../../components/adminPageWrapper/adminPageWrapper";
import "react-datepicker/dist/react-datepicker.css";
import './editUserPage.scss';
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";


const EditUserPage = () => {
	registerLocale('ru', ru);
	const navigate = useNavigate();
	const {userId} = useParams();
	const [currentUser, setCurrentUser] = useState({});
	const [avatar, setAvatar] = useState('');
	const [formData, setFormData] = useState({
		name: '',
		surname: '',
		patronym: '',
		email: '',
		phone: '',
		role: ADMIN_ROLE,
		password: '',
		gender: 'male',
		birthday: new Date()
	});
	const [errors, setErrors] = useState({
		email: null,
		phone: null
	})

	const getCurrentUser = async () => {
		await getUser(userId)
		.then(res => {
			setCurrentUser(res);
			setFormData({
				name: res.name,
				surname: res.surname,
				patronym: res.patronym,
				email: res.email,
				phone: res.phone,
				role: res.role,
				password: res.password,
				gender: res.gender,
				birthday: new Date(res.birthday)
			});
			setAvatar(res.avatarUrl ? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.avatarUrl}` : '')
		})
	}

	useEffect(() => {
		getCurrentUser();
	}, []);

	const changeHandler = (field, value) => {
		setFormData(p => ({...p, [field]: value}));
	};

	const attachAvatarHandler = (file) => {
		attachHandler(file, (f, c) => setAvatar({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		currentUser.name = formData.name;
		currentUser.surname = formData.surname;
		currentUser.patronym = formData.patronym;
		if ((formData.role === COURIER_ROLE) || (formData.role === USER_ROLE)) {
			currentUser.phone = formData.phone;
		}
		if ((formData.role === ADMIN_ROLE) || (formData.role === MERCHANT_ROLE)) {
			currentUser.email = formData.email;
			currentUser.password = formData.password;
		}
		currentUser.role = formData.role;
		currentUser.gender = formData.gender;
		currentUser.birthday = formData.birthday;

		if (avatar?.file) {
			currentUser.avatar = avatar && (await uploadHandler(avatar));
		}

		await currentUser.save()
		.then(() => {
			if (Object.keys(currentUser.errors).length > 0) {
				setErrors(currentUser.errors);
			} else {
				navigate('/admin/users')
			}
		})
	};

	return (
		<ProtectedWrapper role={ADMIN_ROLE}>
			<AdminPageWrapper>
				<div>
					<Button
						variant="dark"
						onClick={() => navigate('/admin/users')}
						className="marginBottom20"
					>Назад</Button>
					<h1 className="marginBottom40">Редактирование пользователя</h1>
					<div className="marginBottom20">
						<DropZoneUploader
							preview={avatar}
							acceptPreviewHandler={(f) => {
								if (f) attachAvatarHandler(f[0]);
							}}
							className="marginBottom20"
							text="Прикрепите аватар пользователя в формате jpeg или png"
						/>
					</div>
					<div className="grid3x marginBottom20">
						<InputGroup>
							<InputGroup.Text>Фамилия</InputGroup.Text>
							<Form.Control
								placeholder="Введите фамилию"
								onChange={e => changeHandler('surname', e.target.value)}
								value={formData.surname}
							/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Имя</InputGroup.Text>
							<Form.Control
								placeholder="Введите имя"
								onChange={e => changeHandler('name', e.target.value)}
								value={formData.name}
							/>
						</InputGroup>
						<InputGroup>
							<InputGroup.Text>Отчество</InputGroup.Text>
							<Form.Control
								placeholder="Введите отчество"
								onChange={e => changeHandler('patronym', e.target.value)}
								value={formData.patronym}
							/>
						</InputGroup>
					</div>
					<div className="grid3x marginBottom20">
						<InputGroup style={{ flexWrap: 'nowrap'}}>
							<InputGroup.Text>Дата рождения</InputGroup.Text>
							<DatePicker
								locale="ru"
								selected={formData.birthday}
								onChange={(date) => changeHandler('birthday', date)}
								wrapperClassName="DatePikerWrapper"
								className="DatePikerInput"
								dateFormat="dd.MM.yyyy"
							/>
						</InputGroup>
						<InputGroup style={{ flexWrap: 'nowrap'}}>
							<InputGroup.Text>Пол</InputGroup.Text>
							<ButtonGroup style={{width: '100%'}}>
								<Button
									onClick={() => changeHandler('gender', 'male')}
									variant={formData.gender === 'male' ? "dark" : "secondary"}>Мужчина</Button>
								<Button
									onClick={() => changeHandler('gender', 'female')}
									variant={formData.gender === 'female' ? "dark" : "secondary"}>Женщина</Button>
							</ButtonGroup>
						</InputGroup>
						<InputGroup style={{width: '100%'}}>
							<InputGroup.Text>Роль</InputGroup.Text>
							<Form.Select
								aria-label="Выберите роль"
								aria-placeholder="Выберите роль"
								onChange={e => {
									changeHandler('role', e.target.value);
								}}
								value={formData.role}
							>
								{AllRolesArray.map(role => (
									<option value={role.value} key={role.value}>{role.label}</option>
								))}
							</Form.Select>
						</InputGroup>
					</div>
					<div className="grid2x marginBottom20">
						{((formData.role === ADMIN_ROLE) || (formData.role === MERCHANT_ROLE)) && (
							<InputGroup>
								<InputGroup.Text>Email</InputGroup.Text>
								<Form.Control
									placeholder="Введите email"
									onChange={e => changeHandler('email', e.target.value)}
									value={formData.email}
									isInvalid={!!errors.email}
								/>
								<Form.Control.Feedback type="invalid">
									{errors?.email?.fullMessage}
								</Form.Control.Feedback>
							</InputGroup>
						)}
						{((formData.role === COURIER_ROLE) || (formData.role === USER_ROLE)) && (
							<InputGroup>
								<InputGroup.Text>Телефон</InputGroup.Text>
								<Form.Control
									placeholder="Введите телефон"
									onChange={e => changeHandler('phone', e.target.value)}
									value={formData.phone}
									isInvalid={!!errors.phone}
								/>
								<Form.Control.Feedback type="invalid">
									{errors?.phone?.fullMessage}
								</Form.Control.Feedback>
							</InputGroup>
						)}

						{((formData.role === ADMIN_ROLE) || (formData.role === MERCHANT_ROLE)) && (
							<InputGroup>
								<InputGroup.Text>Пароль</InputGroup.Text>
								<Form.Control
									placeholder="Введите пароль"
									onChange={e => changeHandler('password', e.target.value)}
									value={formData.password}
								/>
							</InputGroup>
						)}
					</div>
					<div className="flex-center">
						<Button
							onClick={() => submitHandler()}
							variant="dark"
							disabled={
								formData.role === ADMIN_ROLE || formData.role === MERCHANT_ROLE
									? !formData.email
									: formData.role === COURIER_ROLE || formData.role === USER_ROLE
										? !formData.phone
										: false
							}
						>Сохранить</Button>
					</div>
				</div>
			</AdminPageWrapper>
		</ProtectedWrapper>
	)
}

export default EditUserPage;