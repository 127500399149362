import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Card, Spinner} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {getProductsForMarketplace} from "../../../api/productAPI";

const ProductsPage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [products, setProducts] = useState([]);

	useEffect(() => {
		getProductsForMarketplace(marketplaceId)
		.then(res => {
			setProducts(res);
		})
	}, []);

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{products
					? products.length > 0
						? (
							<>
								<div className="block marginBottom20">
									<h1 className="marginBottom20">Черновики товаров</h1>
									<div className="grid4x">
										<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/create`);}}>
											<h3>Добавить товар </h3>
										</Button>
										{products.filter(i => i.status === 'draft').map(product => (
											<Card key={product?.unverifiedChanges?.name || product.name}>
												<div style={{width: '100%', height: '200px'}}>
													<img
														style={{ objectFit: 'cover', objectPosition: 'center', height: '100%', width: '100%'}}
														src={`${window?.__CONFIG__?.REACT_APP_BASE_URL}${product.unverifiedImageUrl || product.imageUrl}`} alt=""/>
												</div>
												<Card.Body>
													<Card.Text style={{ display: 'flex', alignItems: 'center'}}>
														<h5><b>{`${(product.discountPrice / 100) || (product.price / 100)} ₽`}</b></h5>
														<h6 style={{margin: '0 0 0 12px'}}>{`${product.price / 100} ₽`}</h6>
													</Card.Text>
													<Card.Title><b>{product?.unverifiedChanges?.name || product.name}</b></Card.Title>
													{/*<Card.Text>{product?.unverifiedChanges?.description || product.description}</Card.Text>*/}
													<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/${product.id}`);}}>
														Редактировать
													</Button>
												</Card.Body>
											</Card>
										))}
									</div>
								</div>
								<div className="block marginBottom20">
									<h1 className="marginBottom20">Товары ожидающие верификации</h1>
									<div className="grid4x">
										{products.filter(i => i.status === 'verification').map(product => (
											<Card key={product?.unverifiedChanges?.name || product.name}>
												<Card.Img
													style={{height: '200px'}}
													variant="top"
													src={`${window?.__CONFIG__?.REACT_APP_BASE_URL}${product.unverifiedImageUrl || product.imageUrl}`}
												/>
												<Card.Body>
													<Card.Text style={{ display: 'flex', alignItems: 'center'}}>
														<h5><b>{`${product.discountPrice / 100} ₽`}</b></h5>
														<h6 style={{margin: '0 0 0 12px'}}>{`${product.price / 100} ₽`}</h6>
													</Card.Text>
													<Card.Title><b>{product?.unverifiedChanges?.name || product.name}</b></Card.Title>
													{/*<Card.Text>{product?.unverifiedChanges?.description || product.description}</Card.Text>*/}
													<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/${product.id}`);}}>
														Редактировать
													</Button>
												</Card.Body>
											</Card>
										))}
									</div>
								</div>
								<div className="block marginBottom20">
									<h1 className="marginBottom20">Опубликованные товары</h1>
									<div className="grid4x">
										{products.filter(i => i.status === 'published').map(product => (
											<Card key={product?.unverifiedChanges?.name || product.name}>
												<Card.Img
													style={{height: '200px'}}
													variant="top"
													src={`${window?.__CONFIG__?.REACT_APP_BASE_URL}${product.unverifiedImageUrl || product.imageUrl}`}
												/>
												<Card.Body>
													<Card.Text style={{ display: 'flex', alignItems: 'center'}}>
														<h5><b>{`${product.discountPrice / 100} ₽`}</b></h5>
														<h6 style={{margin: '0 0 0 12px'}}>{`${product.price / 100} ₽`}</h6>
													</Card.Text>
													<Card.Title><b>{product?.unverifiedChanges?.name || product.name}</b></Card.Title>
													{/*<Card.Text>{product?.unverifiedChanges?.description || product.description}</Card.Text>*/}
													<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/${product.id}`);}}>
														Редактировать
													</Button>
												</Card.Body>
											</Card>
										))}
									</div>
								</div>
							</>
						)
						: (
							<div className="flex-center">
								<h1 className="marginRight20">Товары не найдены</h1>
								<Button variant="dark" onClick={() => {navigate(`/merchant/${marketplaceId}/products/create`);}}>
									Добавить товар
								</Button>
							</div>
						)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default ProductsPage