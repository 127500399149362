import {TOKEN} from "../helpers/constants/localStorageConstants";
import { SpraypaintBase, MiddlewareStack } from "spraypaint";

let middleware = new MiddlewareStack();

middleware.beforeFilters.push((url, options) => {
	const T = localStorage.getItem(TOKEN);
	if (options && options.headers) {
		options.headers["Authorization"] = `Bearer ${T}`;
	}
});

middleware.afterFilters.push((response) => {
	if (response.status === 401) {
		localStorage.removeItem(TOKEN);
		window.location.href = "/auth";
		throw "abort";
	}
});


const ApplicationRecord = SpraypaintBase.extend({
	static: {
		baseUrl: `${window?.__CONFIG__?.REACT_APP_BASE_URL}`,
		apiNamespace: `${window?.__CONFIG__?.REACT_APP_API_NAMESPACE}`
	}
});

ApplicationRecord.middlewareStack = middleware;

export default ApplicationRecord;