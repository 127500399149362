const AWAITING_PAYMENT = 'awaiting_payment'; // ожидает оплаты
const AWAITING_PROCCESING = 'awaiting_processing'; // ожидает обработки
const PROCESSING = 'processing'; // в обработке
const AWAITING_PICKUP = 'awaiting_pickup'; // ожидает отправки
const PICKEDUP_COURIER = 'picked_up_by_courier'; // у курьера
const IN_TRANSIT = 'in_transit'; // в пути
const DELIVERED = 'delivered'; // доставлен
const CANCELED_ADMIN = 'canceled_by_admin'; // отменен админом
const CANCELED_MERCHANT = 'canceled_by_merchant'; // отменен продавцом
const CANCELED_COURIER = 'canceled_by_courier'; // отменен курьером
const CANCELED_USER = 'canceled_by_user'; // отменен получателем
const COURIER = 'courier'; // доставка курьером
const SELF_PICKUP = 'self_pickup'; // самовывоз

const OREDER_STATUSES = {
	[AWAITING_PAYMENT]: {origin: 'awaiting_payment', translate: 'Ожидает оплаты'},
	[AWAITING_PROCCESING]: {origin: 'awaiting_processing', translate: 'Ожидает обработки'},
	[PROCESSING]: {origin: 'processing', translate: 'В обработке'},
	[AWAITING_PICKUP]: {origin: 'awaiting_pickup', translate: 'Ожидает выдачи'},
	[PICKEDUP_COURIER]: {origin: 'picked_up_by_courier', translate: 'Передан курьеру'},
	[IN_TRANSIT]: {origin: 'in_transit', translate: 'В пути'},
	[DELIVERED]: {origin: 'delivered', translate: 'Доставлен'},
	[CANCELED_ADMIN]: {origin: 'canceled_by_admin', translate: 'Отменен администратором'},
	[CANCELED_MERCHANT]: {origin: 'canceled_by_merchant', translate: 'Отменен продавцом'},
	[CANCELED_COURIER]: {origin: 'canceled_by_courier', translate: 'Отменен курьером'},
	[CANCELED_USER]: {origin: 'canceled_by_user', translate: 'Отменен пользователем'},
};

const DELIVERE_STATUSES = {
	[COURIER]: {origin: 'courier', translate: 'Курьером'},
	[SELF_PICKUP]: {origin: 'self_pickup', translate: 'Самовывоз'},
};

const OREDER_STATUSES_ARRAY = [
	{value: 'awaiting_payment', text: 'Ожидает оплаты'},
	{value: 'awaiting_processing', text: 'Ожидает обработки'},
	{value: 'processing', text: 'В обработке'},
	{value: 'awaiting_pickup', text: 'Ожидает отправки'},
	{value: 'picked_up_by_courier', text: 'Передан курьеру'},
	{value: 'in_transit', text: 'В пути'},
	{value: 'delivered', text: 'Доставлен'},
	{value: 'canceled_by_admin', text: 'Отменен администратором'},
	{value: 'canceled_by_merchant', text: 'Отменен продавцом'},
	{value: 'canceled_by_courier', text: 'Отменен курьером'},
	{value: 'canceled_by_user', text: 'Отменен пользователем'},
];

const PAYMENT_TYPES = {
	credit_card_online: 'Картой онлайн',
	credit_card_courier: 'Картой курьеру',
	cash_courier: 'Наличными курьеру'
}

export {
	PAYMENT_TYPES,
	OREDER_STATUSES_ARRAY,
	OREDER_STATUSES,
	DELIVERE_STATUSES,
	AWAITING_PAYMENT,
	AWAITING_PROCCESING,
	PROCESSING,
	AWAITING_PICKUP,
	PICKEDUP_COURIER,
	IN_TRANSIT,
	DELIVERED,
	CANCELED_ADMIN,
	CANCELED_MERCHANT,
	CANCELED_COURIER,
	CANCELED_USER
}