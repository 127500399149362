import {attr} from "spraypaint";
import isEqual from 'lodash.isequal';
import ApplicationRecord from "./baseModel";

const dirtyChecker = (prior, current) => !(isEqual(prior, current))

const ServiceConfigurationsModel = ApplicationRecord.extend({
	static: { jsonapiType: "service_configurations" },
	attrs: {
		id: attr(),
		legalEntityFile: attr(),
		legalEntityFileUrl: attr(),
		paymentProcessorAgreementFile: attr(),
		paymentProcessorAgreementFileUrl: attr(),
		privacyPolicyFile: attr(),
		privacyPolicyFileUrl: attr(),
		termsOfUseFile: attr(),
		termsOfUseFileUrl: attr(),
		appConfig: attr({dirtyChecker: dirtyChecker}),
		addTagToRestaurantsLayout: attr(),
		addTagToStoresLayout: attr(),
	}
})

export default ServiceConfigurationsModel