import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";

import './statisticsPage.scss';

const StatisticsPage = () => {
	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				<div className='StatisticsPageRoot'>
					<h1>Функционал в разработке</h1>
				</div>
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default StatisticsPage