import OrderModel from "../models/orderModel";

const getAllOrders = async (page) => {
	const res = await OrderModel
	.page(page)
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getAllOrdersByDeliveryType = async (page, deliveryType, hasCourier) => {
	const filters = !hasCourier ? deliveryType === 'courier' ? {
		deliveryType: deliveryType,
		hasCourier: hasCourier,
		status: ['awaiting_processing', 'processing', 'awaiting_pickup']
	} : {
		deliveryType: deliveryType,
	} : {
		deliveryType: deliveryType,
		hasCourier: hasCourier
	};

	const res = await OrderModel
	.where(filters)
	.page(page)
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getOrdersForMarketplace = async (marketplaceId, page) => {
	const res = await OrderModel
	.where({marketplaceId: marketplaceId})
	.page(page)
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const getFilterOrdersForMarketplace = async (marketplaceId, status) => {
	const res = await OrderModel.where({marketplaceId: marketplaceId, status: status}).all();
	return res.data;
};

const searchAllOrdersByNumber = async (orderNumber) => {
	const res = await OrderModel.where({publicUid: {match: orderNumber}}).all();
	return res.data;
};

const searchAllOrdersByDeliveryType = async (orderNumber, page, deliveryType, hasCourier) => {
	const filters = !hasCourier ? deliveryType === 'courier' ? {
		deliveryType: deliveryType,
		hasCourier: hasCourier,
		status: ['awaiting_processing', 'processing', 'awaiting_pickup'],
		publicUid: {match: orderNumber}
	} : {
		deliveryType: deliveryType,
		publicUid: {match: orderNumber}
	} : {
		deliveryType: deliveryType,
		hasCourier: hasCourier,
		publicUid: {match: orderNumber}
	};

	const res = await OrderModel
	.where(filters)
	.page(page)
	.per(15)
	.stats({ total: 'count' })
	.all();

	return {
		data: res.data,
		total: res.meta.stats.total.count
	};
};

const searchOrdersByNumber = async (marketplaceId, orderNumber) => {
	const res = await OrderModel.where({marketplaceId: marketplaceId, publicUid: {match: orderNumber}}).all();
	return res.data;
};

const getOrder = async (orderId) => {
	const res = await OrderModel.includes([{order_items: 'product'}]).find(orderId);
	return res.data;
};

export {
	getAllOrders,
	getAllOrdersByDeliveryType,
	getOrdersForMarketplace,
	getFilterOrdersForMarketplace,
	searchAllOrdersByNumber,
	searchAllOrdersByDeliveryType,
	searchOrdersByNumber,
	getOrder
}