import {useEffect, useState} from 'react';
import { format } from 'date-fns';
import {useNavigate, useParams} from "react-router-dom";
import {Table, Button, Spinner, Form, InputGroup} from "react-bootstrap";
import {getOrdersForMarketplace, getFilterOrdersForMarketplace, searchOrdersByNumber} from "../../../api/orderAPI";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {OREDER_STATUSES, OREDER_STATUSES_ARRAY, DELIVERE_STATUSES} from "../../../helpers/constants/statuses";
import PaginationComponent from "../../../components/pagination/pagination";

const OrdersPage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [orders, setOrders] = useState([]);
	const [total, setTotal] = useState(0);
	const [status, setStatus] = useState('all');
	const [activePage, setActivePage] = useState(1);
	const [searchStr, setSearchStr] = useState('');

	const getOrders = async (pageNumber = null) => {
		await getOrdersForMarketplace(marketplaceId, pageNumber || activePage)
		.then(res => {
			setOrders(res.data);
			setTotal(res.total);
		})
	}

	useEffect(() => {
		getOrders();
	}, []);

	const handlePageChange = (pageNumber) => {
		setActivePage(pageNumber);
		getOrders(pageNumber);
	};

	const resetFiltersHandler = async () => {
		await getOrders();
		setStatus('all');
		setSearchStr('');
	};

	const searchHandler = async () => {
		await searchOrdersByNumber(marketplaceId, searchStr)
		.then(res => {
			setOrders(res);
		})
	};

	const filterHandler = async (v) => {
		setStatus(v);
		await getFilterOrdersForMarketplace(marketplaceId, v)
		.then(res => {
			setOrders(res);
		})
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{orders
					? orders.length > 0
						? (
							<>
								<div className="grid3x marginBottom40">
									<InputGroup>
										<InputGroup.Text>Поиск</InputGroup.Text>
										<Form.Control
											aria-label="Поиск"
											placeholder="Введите номер заказа"
											onChange={e => setSearchStr(e.target.value)}
											value={searchStr}
										/>
										<Button
											variant="dark"
											onClick={() => searchHandler()}
										>Найти</Button>
									</InputGroup>
									<Form.Select
										aria-label="Выберите статус заказа"
										aria-placeholder="Выберите статус заказа"
										onChange={e => filterHandler(e.target.value)}
										value={status}
									>
										<option value="all" key="all">Все статусы</option>
										{OREDER_STATUSES_ARRAY.map(st => (
											<option value={st.value} key={st.value}>{st.text}</option>
										))}
									</Form.Select>
									<Button
										variant="dark"
										onClick={() => resetFiltersHandler()}
										disabled={status === 'all' && searchStr === ''}
									>Сбросить фильтры</Button>
								</div>
								<Table>
									<thead>
									<tr>
										<th>Номер заказа</th>
										<th>Статус заказа</th>
										<th>Тип доставки</th>
										<th>Дата создания заказа</th>
										<th>Дата обновления заказа</th>
									</tr>
									</thead>
									<tbody>
									{orders.map(order => (
										<tr key={order.id}>
											<td
												onClick={() => navigate(`/merchant/${marketplaceId}/orders/${order.id}`)}
												style={{textDecoration: 'underline', cursor: 'pointer'}}
											>
												{order.publicUid || 'Номер отсутствует'}
											</td>
											<td>{OREDER_STATUSES[order.status].translate}</td>
											<td>{DELIVERE_STATUSES[order.deliveryType].translate}</td>
											<td>{format(new Date(order.createdAt), 'dd.MM.yyyy - hh:mm')}</td>
											<td>{format(new Date(order.updatedAt), 'dd.MM.yyyy - hh:mm')}</td>
										</tr>
									))}
									</tbody>
								</Table>
								<PaginationComponent
									total={total}
									activePage={activePage}
									handlePageChange={(pn) => handlePageChange(pn)}
								/>
							</>
						)
						: (
							<div className="flex-center">
								<h1 style={{display: 'block'}}>Заказов пока нет</h1>
								{status !== 'all' && (
									<div>
										<Button
											variant="dark"
											onClick={() => resetFiltersHandler()}
											disabled={status === 'all' && searchStr === ''}
										>Сбросить фильтры</Button>
									</div>
								)}
							</div>
						)
					 : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default OrdersPage