import {useEffect, useState} from 'react';
import {Button, Form, InputGroup} from "react-bootstrap";
import MarketplaceModel from "../../../models/marketplaceModel";
import MarketplaceLocationsModel from "../../../models/marketplaceLocationsModel";
import {getAllCities} from '../../../api/cityAPI';
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import AddLocation from "../../../components/addLocation/addLocation";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {attachHandler, uploadHandler} from '../../../helpers/directUpload';
import {getAllTags} from "../../../api/tagsAPI";
import MarketplaceTagModel from "../../../models/marketplaceTagModel";


const CreateMarketplacePage = () => {
	const [cities, setCities] = useState([]);
	const [locations, setLocations] = useState([]);
	const [logo, setLogo] = useState('');
	const [tags, setTags] = useState([]);
	const [selectedTag, setSelectedTag] = useState({value: 'all'});
	const [tagArray, setTagArray] = useState([]);
	const [background, setBackground] = useState('');
	const [mainImage, setMainImage] = useState('');
	const [formData, setFormData] = useState({
		name: '',
		description: '',
		status: 'draft',
		city: ''
	});

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res.filter(t => !t.rootTag)));
	};

	useEffect(() => {
		getAllCities().then(
			(res) => setCities(res)
		)
		getTags();
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}))
	};

	const attachLogoHandler = (file) => {
		attachHandler(file, (f, c) => setLogo({ file: f, checkSum: c }));
	};

	const attachBackgroundHandler = (file) => {
		attachHandler(file, (f, c) => setBackground({ file: f, checkSum: c }));
	};

	const attachMainHandler = (file) => {
		attachHandler(file, (f, c) => setMainImage({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		const currentMarketplace = new MarketplaceModel();

		currentMarketplace.unverifiedChanges = {
			name: formData.name,
			description: formData.description
		};
		currentMarketplace.city = cities.find(c => c.id === formData.city);

		if (logo?.file) {
			currentMarketplace.unverifiedLogo = logo && (await uploadHandler(logo));
		}

		if (background?.file) {
			currentMarketplace.unverifiedCatalogBackground = background && (await uploadHandler(background));
		}

		if (mainImage?.file) {
			currentMarketplace.unverifiedImage = mainImage && (await uploadHandler(mainImage));
		}

		await currentMarketplace.save({ with: "city.id"})
		.then(() => {
			locations.forEach(async loc => {
				const currentLocation = new MarketplaceLocationsModel();
				currentLocation.name = loc.name;
				currentLocation.address = loc.address;
				currentLocation.lat = loc.lat;
				currentLocation.lng = loc.lng;
				currentLocation.marketplace = currentMarketplace;
				await currentLocation.save({ with: "marketplace.id"})
			});

			tagArray.forEach(async t => {
				const newMarketTag = new MarketplaceTagModel();
				newMarketTag.tag = t.tag;
				newMarketTag.marketplace = currentMarketplace;

				await newMarketTag.save({ with: ['marketplace.id', 'tag.id']});
			})
		})
		.then(() => {
			setTimeout(() => {
				window.location.pathname = '/merchant'
			}, 1000)
		})
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper lightVersion>
				<div>
					<h1 className="marginBottom20">Создание нового бизнеса</h1>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon1">Название (обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Название"
							aria-label="Название"
							aria-describedby="basic-addon1"
							value={formData.name}
							onChange={e => changeHandler('name', e.target.value)}
						/>
					</InputGroup>
					<InputGroup className="mb-3">
						<InputGroup.Text id="basic-addon2">Описание (обязательное поле)</InputGroup.Text>
						<Form.Control
							placeholder="Описание"
							aria-label="Описание"
							aria-describedby="basic-addon2"
							value={formData.description}
							onChange={e => changeHandler('description', e.target.value)}
						/>
					</InputGroup>
					<Form.Select
						className="marginBottom20"
						value={formData.city}
						onChange={(c) => changeHandler('city', c.target.value)}
					>
						<option>Выберите город</option>
						{cities.map(city => (
							<option key={city.name} value={city.id}>{city.name}</option>
						))}
					</Form.Select>
					<div className="block marginBottom10">
						<h5 className="marginBottom20"><b>Категория бизнеса</b></h5>
						<div className='grid2x'>
							<div className="flex flex-wrap">
								{tagArray?.length === 0 ? (
									<p>У бизнеса нет категорий</p>
								) : (
									tagArray.map(t => (
										<div className="ActiveTag">
											<b>{t.tag.name}</b>
											<Button
												variant="outline-danger"
												onClick={() => setTagArray(tagArray.filter(tf => tf.tag.id !== t.tag.id))}
											>Удалить</Button>
										</div>
									))
								)}
							</div>
							<div className='flex'>
								<InputGroup style={{width: '400px'}}>
									<InputGroup.Text>Категория</InputGroup.Text>
									<Form.Select
										aria-label="Выберите роль"
										aria-placeholder="Выберите роль"
										onChange={e => setSelectedTag({
											value: e.target.value, tag: tags.find(i => i.id === e.target.value)
										})}
										value={selectedTag.value}
									>
										<option value={'all'} disabled>Выберите категорию</option>
										{tags.map(tag => (
											<option
												value={tag.id}
												key={tag.id}
												disabled={tagArray.find(t => t.tag.id === tag.id)}
											>
												{tag.name}
											</option>
										))}
									</Form.Select>
								</InputGroup>
								<Button
									variant='dark'
									disabled={selectedTag.value === 'all'}
									onClick={() => {
										setTagArray(p => ([...p, selectedTag]))
										setSelectedTag({value: 'all'});
									}}
								>Добавить категорию</Button>
							</div>
						</div>
					</div>
					<DropZoneUploader
						preview={logo}
						acceptPreviewHandler={(f) => {
							if (f) attachLogoHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите логотип вашего бизнеса в формате jpeg или png"
					/>
					<DropZoneUploader
						preview={background}
						acceptPreviewHandler={(f) => {
							if (f) attachBackgroundHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите фон для вашего каталога в формате jpeg или png"
					/>
					<DropZoneUploader
						preview={mainImage}
						acceptPreviewHandler={(f) => {
							if (f) attachMainHandler(f[0]);
						}}
						className="marginBottom20"
						text="Прикрепите ваше основное изображение в формате jpeg или png"
					/>

					<AddLocation
						locations={locations}
						setNewLocation={(newLocation) => setLocations(p => ([...p, newLocation]))}
						removeLocationHandler={(id) => {
							setLocations(locations.filter(i => i.arrayId !== id))
						}}
					/>
				</div>

				<Button
					variant="dark"
					style={{display: 'block', width: '100%'}}
					onClick={() => submitHandler()}
					className="marginBottom20"
					disabled={!formData.name || !formData.description || !formData.city}
				>Сохранить</Button>
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default CreateMarketplacePage