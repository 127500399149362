import {Button, Form, InputGroup} from "react-bootstrap";
import Map from '../map/map';
import {useState} from "react";

const AddLocation = ({locations, addLocationHandlerInEditMode, setNewLocation, removeLocationHandler, existingLocations, mode = 'new', removeExistingMarketLocationHandler}) => {
	const [currentDotOnMap, setCurrentDotOnMap] = useState({
		lat: 0,
		lng: 0,
		addressFromMap: ''
	});
	const [locationInfo, setLocationInfo] = useState({
		name: '',
		address: '',
		block: '',
		office: '',
		floor: ''
	});

	const changeHandler = (name, value) => {
		setLocationInfo(p => ({...p, [name]: value}))
	};

	const submitNewLocationHandler = () => {
		const fullAddress = `
		${currentDotOnMap.addressFromMap || ''}${locationInfo.block ? `, подъезд ${locationInfo.block}` : ''}${locationInfo.floor ? `, этаж ${locationInfo.floor}` : ''}${locationInfo.office ? `, офис ${locationInfo.office}` : ''}`;

		if (mode === 'edit') {
			addLocationHandlerInEditMode({
				arrayId: locations.length + 1,
				name: locationInfo.name,
				lat: currentDotOnMap?.lat || 0,
				lng: currentDotOnMap?.lng || 0,
				address: fullAddress
			})
		} else {
			setNewLocation({
				arrayId: locations.length + 1,
				name: locationInfo.name,
				lat: currentDotOnMap?.lat || 0,
				lng: currentDotOnMap?.lng || 0,
				address: fullAddress
			});
		}

		setLocationInfo({
			name: '',
			address: '',
			block: '',
			office: '',
			floor: ''
		});

		setCurrentDotOnMap({
			lat: 0,
			lng: 0,
			addressFromMap: ''
		})
	};

	const removeDotHandler = () => {
		setCurrentDotOnMap({
			lat: 0,
			lng: 0,
			addressFromMap: ''
		})
		changeHandler('address', '')
	};

	return (
		<div className="marginBottom60 marginTop60">
			<h1 className="marginBottom20">Добавить точку самовывоза</h1>
			{(mode === 'edit' && existingLocations.length > 0) && (
				<div className="block marginBottom20">
					<p><b>Ранее добавленные точки</b></p>
					{existingLocations.map(loc => (
						<div className="flex-space-between" key={loc.address}>
							<div>
								<p>{`${loc.name} - ${loc.address}`}</p>
							</div>
							<Button
								variant="dark"
								onClick={() => removeExistingMarketLocationHandler(loc)}
							>Удалить точку</Button>
						</div>
					))}
				</div>
			)}
			<div className="grid2x">
				<Map
					mode={mode}
					currentDotOnMap={currentDotOnMap}
					existingLocations={existingLocations}
					newLocations={locations}
					setCurrentDotOnMap={(data) => {
						setCurrentDotOnMap(data);
						changeHandler('address', data.addressFromMap)
					}}
					removeDotHandler={() => removeDotHandler()}
				/>
				<div>
					{mode === 'new' && (
						<div className='block marginBottom20'>
							<p><b>{mode === 'new' ? 'Добавленные точки:' : 'Новые добавленные точки'}</b></p>
							{locations.length > 0
								? (
									locations.map(loc => (
										<div className="flex-space-between" key={loc.address}>
											<div>
												<p>{`${loc.name} - ${loc.address}`}</p>
											</div>
											<Button
												variant="dark"
												onClick={() => removeLocationHandler(loc.arrayId)}
											>Удалить точку</Button>
										</div>
									))
								)
								: (<p>{mode === 'new' ? 'Пока нет добавленных точек' : 'Пока нет новых добавленных точек'}</p>)
							}
						</div>
					)}
					<div className="block">
						<p><b>Информация о точке:</b></p>
						<div>
							<InputGroup className="marginBottom20">
								<InputGroup.Text>Название точки</InputGroup.Text>
								<Form.Control
									placeholder="Название точки"
									onChange={e => changeHandler('name', e.target.value)}
									value={locationInfo.name}
								/>
							</InputGroup>
							<InputGroup className="marginBottom20">
								<InputGroup.Text>Адрес</InputGroup.Text>
								<Form.Control
									placeholder="Адрес"
									disabled
									value={locationInfo.address}
								/>
							</InputGroup>
							<div className='flex-space-between'>
								<InputGroup>
									<InputGroup.Text>Подъезд</InputGroup.Text>
									<Form.Control
										placeholder="Подъезд"
										onChange={e => changeHandler('block', e.target.value)}
										value={locationInfo.block}
									/>
								</InputGroup>
								<InputGroup>
									<InputGroup.Text>Офис</InputGroup.Text>
									<Form.Control
										placeholder="Офис"
										onChange={e => changeHandler('office', e.target.value)}
										value={locationInfo.office}
									/>
								</InputGroup>
								<InputGroup>
									<InputGroup.Text>Этаж</InputGroup.Text>
									<Form.Control
										placeholder="Этаж"
										onChange={e => changeHandler('floor', e.target.value)}
										value={locationInfo.floor}
									/>
								</InputGroup>
							</div>
						</div>
						<Button
							variant="dark"
							style={{display: 'block', width: '100%'}}
							onClick={() => submitNewLocationHandler()}
							disabled={!locationInfo.address}
							className="marginTop20"
						>{mode === 'new' ? 'Добавить точку' : 'Добавить новую точку'}</Button>
					</div>
				</div>
			</div>
		</div>
	)
};

export default AddLocation;