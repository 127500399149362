import {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {Button, Spinner, Form, InputGroup} from "react-bootstrap";
import MerchantPageWrapper from "../../../components/merchantPageWrapper/merchantPageWrapper";
import ProtectedWrapper from "../../../components/protectedWrapper/protectedWrapper";
import {MERCHANT_ROLE} from "../../../helpers/constants/roles";
import {getMarketplace} from "../../../api/marketplaceAPI";
import DropZoneUploader from "../../../components/dropZoneUploader/dropZoneUploader";
import {attachHandler, uploadHandler} from "../../../helpers/directUpload";
import {getLocationsForMarketplace} from "../../../api/marketplaceLocationsAPI";
import AddLocation from "../../../components/addLocation/addLocation";
import MarketplaceLocationsModel from "../../../models/marketplaceLocationsModel";
import {getAllTags} from "../../../api/tagsAPI";
import MarketplaceTagModel from "../../../models/marketplaceTagModel";

const EditMarketplacePage = () => {
	const { marketplaceId } = useParams();
	const navigate = useNavigate();
	const [marketplace, setMarketplace] = useState(null);
	const [tags, setTags] = useState([]);
	const [selectedTag, setSelectedTag] = useState('all');
	const [formData, setFormData] = useState({
		name: '',
		description: '',
	});
	const [logo, setLogo] = useState('');
	const [background, setBackground] = useState('');
	const [mainImage, setMainImage] = useState('');
	const [newLocations, setNewLocations] = useState([]);
	const [marketLocations, setMarketLocations] = useState([]);

	const getLocations = async () => {
		await getLocationsForMarketplace(marketplaceId)
		.then(res => {
			setMarketLocations(res)
		})
	}

	const getTags = async () => {
		await getAllTags()
		.then(res => setTags(res.filter(t => !t.rootTag)));
	};

	const getCurrentMarketplace = async () => {
		await getMarketplace(marketplaceId)
		.then(res => {
			setMarketplace(res);
			if (res?.status === 'draft') {
				setFormData({
					name: res?.unverifiedChanges?.name || '',
					description: res?.unverifiedChanges?.description || '',
				})
			} else {
				setFormData({
					name: res?.name || '',
					description: res?.description || '',
				})
			}

			setLogo(res.logoUrl || res.unverifiedLogoUrl ? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.unverifiedLogoUrl || res.logoUrl}` : '');
			setBackground(res.catalogBackgroundUrl || res.unverifiedCatalogBackgroundUrl ? `${window?.__CONFIG__?.REACT_APP_BASE_URL}${res.unverifiedCatalogBackgroundUrl || res.catalogBackgroundUrl}` : '');
			// TODO: поправить после того как КОРСЫ пофиксятся
			// setMainImage(res.imageUrl ? res.imageUrl : '');
		})
	};

	useEffect(() => {
		getLocations();
		getTags();
		getCurrentMarketplace();
	}, []);

	const changeHandler = (name, value) => {
		setFormData(p => ({...p, [name]: value}))
	};

	const attachLogoHandler = (file) => {
		attachHandler(file, (f, c) => setLogo({ file: f, checkSum: c }));
	};

	const attachBackgroundHandler = (file) => {
		attachHandler(file, (f, c) => setBackground({ file: f, checkSum: c }));
	};

	const attachMainHandler = (file) => {
		attachHandler(file, (f, c) => setMainImage({ file: f, checkSum: c }));
	};

	const submitHandler = async () => {
		if (marketplace.name !== formData.name || marketplace.description !== formData.description) {
			marketplace.unverifiedChanges = {
				name: formData.name,
				description: formData.description
			};
		}

		if (logo?.file) {
			marketplace.unverifiedLogo = logo && (await uploadHandler(logo));
		}

		if (background?.file) {
			marketplace.unverifiedCatalogBackground = background && (await uploadHandler(background));
		}

		if (mainImage?.file) {
			marketplace.unverifiedImage = mainImage && (await uploadHandler(mainImage));
		}

		await marketplace.save()
		.then(async () => {
			window.location.pathname = `/merchant/${marketplaceId}/marketplace`;
		})
	};

	const removeMarketLocationHandler = async (l) => {
		await l.destroy()
		.then(() => {
			getLocations();
		})
	}

	const deleteMarketTagHandler = async (tag) => {
		await tag.destroy()
		.then(() => {
			getCurrentMarketplace();
		})
	}
	const addMarketTagHandler = async (tagId) => {
		const tag = tags.find(t => t.id === tagId);

		const newMarketTag = new MarketplaceTagModel();
		newMarketTag.tag = tag;
		newMarketTag.marketplace = marketplace;

		await newMarketTag.save({ with: ['marketplace.id', 'tag.id']})
		.then(() => {
			getCurrentMarketplace();
		})
	}

	const saveLocationHandler = async (loc) => {
		const currentLocation = new MarketplaceLocationsModel();
		currentLocation.name = loc.name;
		currentLocation.address = loc.address;
		currentLocation.lat = loc.lat;
		currentLocation.lng = loc.lng;
		currentLocation.marketplace = marketplace;

		await currentLocation.save({ with: "marketplace.id"})
		.then(() => {
			getLocations();
		})
	};

	return (
		<ProtectedWrapper role={MERCHANT_ROLE}>
			<MerchantPageWrapper>
				{marketplace
					? (
						<>
							<div>
								<Button
									variant="dark"
									onClick={() => {navigate(`/merchant/${marketplaceId}/marketplace`)}}
									className="marginBottom20"
								>Назад</Button>
								<h1 className="marginBottom20">Редактирование</h1>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon1">Название</InputGroup.Text>
									<Form.Control
										placeholder="Название"
										aria-label="Название"
										aria-describedby="basic-addon1"
										value={formData.name}
										onChange={e => changeHandler('name', e.target.value)}
									/>
								</InputGroup>
								<InputGroup className="mb-3">
									<InputGroup.Text id="basic-addon1">Описание</InputGroup.Text>
									<Form.Control
										placeholder="Описание"
										aria-label="Описание"
										aria-describedby="basic-addon1"
										value={formData.description}
										onChange={e => changeHandler('description', e.target.value)}
									/>
								</InputGroup>
								<div className="block marginBottom10">
									<h5 className="marginBottom20"><b>Категория бизнеса</b></h5>
									<div className='grid2x'>
										<div className="flex flex-wrap">
											{marketplace?.marketplaceTags?.length === 0 ? (
												<p>У бизнеса нет категорий</p>
											) : (
												marketplace?.marketplaceTags.map(t => (
													<div className="ActiveTag">
														<b>{t.tag.name}</b>
														<Button variant="outline-danger" onClick={() => deleteMarketTagHandler(t)}>Удалить</Button>
													</div>
												))
											)}
										</div>
										<div className='flex'>
											<InputGroup style={{width: '400px'}}>
												<InputGroup.Text>Категория</InputGroup.Text>
												<Form.Select
													aria-label="Выберите роль"
													aria-placeholder="Выберите роль"
													onChange={e => setSelectedTag(e.target.value)}
													value={selectedTag}
												>
													<option value={'all'} disabled>Выберите категорию</option>
													{tags.map(tag => (
														<option value={tag.id} key={tag.id}>{tag.name}</option>
													))}
												</Form.Select>
											</InputGroup>
											<Button
												variant='dark'
												disabled={selectedTag === 'all'}
												onClick={() => {
													addMarketTagHandler(selectedTag);
													setSelectedTag('all');
												}}
											>Добавить категорию</Button>
										</div>
									</div>
								</div>
								<DropZoneUploader
									preview={logo}
									acceptPreviewHandler={(f) => {
										if (f) attachLogoHandler(f[0]);
									}}
									className="marginBottom20"
									text="Прикрепите логотип вашего бизнеса в формате jpeg или png"
								/>
								<DropZoneUploader
									preview={background}
									acceptPreviewHandler={(f) => {
										if (f) attachBackgroundHandler(f[0]);
									}}
									className="marginBottom20"
									text="Прикрепите фон для вашего каталога в формате jpeg или png"
								/>
								<DropZoneUploader
									preview={mainImage}
									acceptPreviewHandler={(f) => {
										if (f) attachMainHandler(f[0]);
									}}
									className="marginBottom20"
									text="Прикрепите ваше основное изображение в формате jpeg или png"
								/>
								<AddLocation
									mode="edit"
									locations={newLocations}
									setNewLocation={(newLocation) => setNewLocations(p => ([...p, newLocation]))}
									removeLocationHandler={(id) => {
										setNewLocations(newLocations.filter(i => i.arrayId !== id))
									}}
									existingLocations={marketLocations}
									addLocationHandlerInEditMode={(loc) => saveLocationHandler(loc)}
									removeExistingMarketLocationHandler={(l) => removeMarketLocationHandler(l)}
								/>
							</div>
							<Button
								variant="dark"
								onClick={() => submitHandler()}
								style={{width: '100%'}}
								className="marginBottom20"
								disabled={!formData.name || !formData.description}
							>Сохранить</Button>
						</>
					) : (
						<Spinner style={{ position: "absolute", top: '50%', left: '50%'}} />
					)
				}
			</MerchantPageWrapper>
		</ProtectedWrapper>
	)
}

export default EditMarketplacePage